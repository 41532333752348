import React from "react"
import styled from "styled-components"

const TableComponent = ({ title, items }) => {
    console.log(items);
    return (
        <Container id="available-weeks">
            <Title dangerouslySetInnerHTML={{ __html: title.html }} />
            <Table>
                <thead>
                    <tr>
                        <th>Week</th>
                        <th>Room Number</th>
                        <th>Room Type</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, i) => (
                        <tr key={i}>
                            <td>{item.week.text}</td>
                            <td>{item.roomnumber.text}</td>
                            <td>{item.roomtype.text}</td>
                            <td>{item.price.text}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}

export default TableComponent

const Container = styled.div`
  display: grid;
  place-items: center;
  width: 90%;
  padding: 80px 0;
  margin: 0 auto;
  @media (max-width: 440px) {
    width: 90%;
    padding: 80px 0;
  }
`

const Title = styled.div`
  font-size: 2rem;
  font-family: "Canela Light", sans-serif;
  text-align: center;
  color: var(--primary-text);
`

const Table = styled.table`
    max-width:750px;
    width:100%;
    border-collapse: collapse;
    margin:0px auto 1rem;
    > thead > tr > th {
        font-size: 1.1rem;
        color: var(--primary-text) !important;
        text-align: left;
        padding:8px;
        border-bottom:30px solid #fFFF;
    }
    > tbody > tr {
        background-color: #f9f9f9;
        border-bottom:15px solid #fFFF;
    }
    > tbody > tr > td {
        font-size:  1.1rem;
        color: var(--primary-text) !important;
        padding:15px;
        border:0;
        border-collapse:none;
        border: 0px;
        border-spacing: 0px;
    }
`;
